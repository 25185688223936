"use client";

import { useEffect, useState } from "react";
import {
  Container,
  Title,
  Button,
  Stack,
  Text,
  Card,
  Group,
  Badge,
  Skeleton,
  Grid,
  Alert,
  Modal,
} from "@mantine/core";
import { useRouter, useSearchParams } from "next/navigation";
import Link from "next/link";
import LogoLetter from "../components/Icons/LogoLetter";




export default function Square({
  authUser,
  merchantData: initialMerchantData,
}) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [merchantData, setMerchantData] = useState(initialMerchantData);
  const [error, setError] = useState(searchParams.get("error"));
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const handleSubscribe = async () => {
    try {
      setIsSubscribing(true);
      const response = await fetch("/api/create-checkout-session", {
        method: "POST",
      });
      const data = await response.json();

      if (data.url) {
        window.location.href = data.url;
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to start checkout process");
    } finally {
      setIsSubscribing(false);
    }
  };


  const handleCancelSubscription = async () => {
    try {
      setIsCanceling(true);
      const response = await fetch("/api/cancel-square-subscription", {
        method: "POST",
      });

      if (!response.ok) {
        throw new Error("Failed to cancel subscription");
      }

      // Close modal first
      setShowCancelModal(false);

      // Add a small delay before reloading
      setTimeout(() => {
        window.location.reload();
      }, 500);

    } catch (error) {

      setError("Failed to cancel subscription");
      setIsCanceling(false);
      setShowCancelModal(false);
    }
  };


  const LogoSection = () => (
    <Group justify="center" mb="xl">
      <LogoLetter style={{ width: "auto", height: "34px" }} />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 20 20"
      >
        <g fill="currentColor">
          <path
            fillRule="evenodd"
            d="M11.5 4.5A1.5 1.5 0 0 0 10 6v3.5H6.5a1.5 1.5 0 0 0 0 3H10V16a1.5 1.5 0 0 0 3 0v-3.5h3.5a1.5 1.5 0 0 0 0-3H13V6a1.5 1.5 0 0 0-1.5-1.5"
            clipRule="evenodd"
            opacity="0.2"
          />
          <path d="M5 10.5a.5.5 0 0 1 0-1h10a.5.5 0 0 1 0 1z" />
          <path d="M9.5 5a.5.5 0 0 1 1 0v10a.5.5 0 0 1-1 0z" />
        </g>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="34"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M4.01 0A4.01 4.01 0 0 0 0 4.01v15.98c0 2.21 1.8 4 4.01 4.01h15.98C22.2 24 24 22.2 24 19.99V4a4.01 4.01 0 0 0-4.01-4H4zm1.62 4.36h12.74c.7 0 1.26.57 1.26 1.27v12.74c0 .7-.56 1.27-1.26 1.27H5.63c-.7 0-1.26-.57-1.26-1.27V5.63a1.27 1.27 0 0 1 1.26-1.27m3.83 4.35a.73.73 0 0 0-.73.73v5.09c0 .4.32.72.72.72h5.1a.73.73 0 0 0 .73-.72V9.44a.73.73 0 0 0-.73-.73h-5.1Z"
        />
      </svg>
    </Group>
  );

  function getSubscriptionBadge(merchantData) {
    if (merchantData.subscription_status === "active") {
      if (merchantData.cancel_at_period_end) {
        return <Badge color="yellow">Canceling</Badge>;
      }
      return <Badge color="green">Active</Badge>;
    }
    return <Badge color="red">Inactive</Badge>;
  }

  function getSubscriptionAlert(merchantData) {
    if (merchantData.subscription_status === "active") {
      if (merchantData.cancel_at_period_end) {
        return (
          <Alert color="yellow" variant="light" title="Subscription Ending">
            Your subscription will end on{" "}
            {new Date(merchantData.current_period_end).toLocaleDateString()}.
          </Alert>
        );
      }
      return (
        <Alert color="teal" variant="light" title="Active Subscription">
          Your subscription will renew on{" "}
          {new Date(merchantData.current_period_end).toLocaleDateString()}
        </Alert>
      );
    }
    return (
      <Alert color="red" variant="light" title="Inactive Subscription">
        Your subscription has ended.
      </Alert>
    );
  }

  if (!authUser) {
    return (
      <Container size="md" py="xl" mt="150px">
        <Stack align="center" spacing="xl">
          <LogoSection />
          <Title order={1}>List Your Products on Stacks on Stacks</Title>
          <Text c="dimmed" ta="center" size="lg">
            Connect your Square store to make your products discoverable on
            Stacks on Stacks, the premier search engine for disc golf retail
            products.
          </Text>
          <Group>
            <Button
              component={Link}
              href="/signin"
              size="md"
              color={"var(--colors-bg-brand)"}
              radius="xl"
            >

              Sign In
            </Button>
            <Button
              component={Link}
              href="/signup"
              size="md"
              variant="outline"
              color={"var(--colors-bg-brand)"}
              radius="xl"
            >
              Sign Up
            </Button>
          </Group>
        </Stack>
      </Container>
    );
  }

  if (!merchantData) {
    return (
      <Container size="md" py="xl" mt="150px">
        <Stack align="center" spacing="xl">
          <LogoSection />
          <Title order={1}>Connect Your Square Store</Title>
          {error && (
            <Alert color="red" variant="light">
              {error}
            </Alert>
          )}
          <Text c="dimmed" ta="center" size="lg">
            Connect your Square account to showcase your products on Stacks on
            Stacks
          </Text>
          <Button
            component={Link}
            href="/api/square-auth"
            size="lg"
            variant="gradient"
            gradient={{ from: "blue", to: "cyan" }}
          >
            Connect Square Store
          </Button>
        </Stack>
      </Container>
    );
  }

  // Connected but not subscribed view
  if (
    !merchantData.subscription_status ||
    merchantData.subscription_status !== "active"
  ) {
    return (
      <Container size="lg" py="xl" mt="150px">
        <Stack spacing="xl">
          <LogoSection />
          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <Stack spacing="md">
              <Group position="apart" align="center">
                <div>
                  <Title order={2} mb="xs">
                    {merchantData.merchant_name}
                  </Title>
                  <Text c="dimmed">
                    Connected and ready to start promoting your discs!
                  </Text>
                </div>
                <Badge color="blue">Connected</Badge>
              </Group>

              <Alert color="yellow" variant="light" title="One Last Step">
                Subscribe to start listing your discs on Stacks on Stacks.
              </Alert>

              <Button
                size="lg"
                variant="gradient"
                gradient={{ from: "violet", to: "cyan" }}
                onClick={handleSubscribe}
                loading={isSubscribing}
              >
                Subscribe Now
              </Button>
            </Stack>
          </Card>
        </Stack>
      </Container>
    );
  }

  // Fully connected and subscribed view
  return (
    <Container size="lg" py="xl" mt="150px">
      <Modal
        opened={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        title="Cancel Subscription"
      >
        <Stack>
          <Text>Are you sure you want to cancel your subscription?</Text>
          <Text size="sm" c="dimmed">
            Your subscription will remain active until the end of the current
            billing period (
            {merchantData.current_period_end
              ? new Date(merchantData.current_period_end).toLocaleDateString()
              : "current period"}
            ).
          </Text>
          <Group position="right">
            <Button variant="light" onClick={() => setShowCancelModal(false)}>
              Keep Subscription
            </Button>
            <Button
              color="red"
              onClick={handleCancelSubscription}
              loading={isCanceling}
            >
              Cancel Subscription
            </Button>
          </Group>
        </Stack>
      </Modal>

      <Stack spacing="xl">
        <LogoSection />
        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Stack spacing="md">
            <Group position="apart" align="center">
              <div>
                <Title order={2} mb="xs">
                  {merchantData.merchant_name}
                </Title>
                <Text c="dimmed">
                  Your discs are now listed on Stacks on Stacks!
                </Text>
              </div>
              {getSubscriptionBadge(merchantData)}
            </Group>


            {getSubscriptionAlert(merchantData)}

            {merchantData.subscription_status === "active" &&
              !merchantData.cancel_at_period_end && (
                <Group position="right">
                  <Button
                    variant="light"
                    color="red"
                    onClick={() => setShowCancelModal(true)}
                  >
                    Cancel Subscription
                  </Button>
                </Group>
              )}
          </Stack>
        </Card>
      </Stack>
    </Container>

  );
}
